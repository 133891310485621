import en from "../assets/locales/en/translation.json";
import th from "../assets/locales/th/translation.json";
import getLanguage from "../utils/getLanguage";

let translationDataEN = en as any;
let translationDataTH = th as any;

const useTranslation = () => {
  const t = (text: any) => {
    const lang = localStorage.getItem("lang");
    return lang === "th"
      ? translationDataTH[text]
      : translationDataEN[text];
  };

  const initializeTranslation = () => {
    const validLanguages = ["en", "th"];
    let language: string | null = getLanguage();
    if (!language) {
      language = localStorage.getItem("lang");
    }
    if (language && validLanguages.includes(language)) {
      localStorage.setItem("lang", language);
    } else {
      localStorage.setItem("lang", "en");
    }

    const data = language === "th" ? th : en;
    const translationData =  data
    if (language === "th") {
      translationDataTH = translationData;
    } else {
      translationDataEN = translationData;
    }
  };

  return { t, initializeTranslation };
};

export default useTranslation;
