import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Select, MenuItem, Typography } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import { ReactComponent as MaintenanceLogo } from "../../assets/images/maintenance.svg";

const Maintenance = () => {
  const navigate = useNavigate();
  const { initializeTranslation, t } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem("lang") || "en");

  const handleLanguageChange = (event: any) => {
    const selectedLang = event.target.value;
    setLang(selectedLang);
    navigate({
      pathname: "/",
      search: `?lang=${selectedLang}`,
    });
    initializeTranslation();
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #E1E1E1",
        borderRadius: 4,
        maxWidth: 292,
        padding: 3,
        marginX: "auto",
        marginY: 0,
      }}
    >
      <Select
        data-testid="maintenance-select"
        value={lang}
        onChange={handleLanguageChange}
        variant="standard"
        disableUnderline
        sx={{
          fontWeight: 600,
          alignSelf: "flex-end",
          ".MuiSvgIcon-root ": {
            fill: "#141312",
          },
        }}
      >
        <MenuItem data-testid="maintenance-th-menuItem" value={"th"}>
          ไทย
        </MenuItem>
        <MenuItem data-testid="maintenance-en-menuItem" value={"en"}>
          English
        </MenuItem>
      </Select>
      <Box
        data-testid="maintenance-logo"
        sx={{
          width: 200,
          height: 200,
          borderRadius: "50%",
          marginBottom: 3,
        }}
      >
        <MaintenanceLogo />
      </Box>
      <Typography
        data-testid="maintenance-title"
        variant="h6"
        sx={{ marginBottom: 2 }}
      >
        {t("Boo!")}
      </Typography>
      <Typography
        data-testid="maintenance-description"
        variant="body2"
        sx={{ textAlign: "center" }}
      >
        {t("Description")}
      </Typography>
    </Box>
  );
};

export default Maintenance;
