import React from "react";
import { AppBar, IconButton, Container } from "@mui/material";
import { ReactComponent as ABCWalletLogo } from "../../assets/images/navbar_abc_logo.svg";

const Navbar = () => {
  return (
    <AppBar
      data-testid="navbar"
      position="sticky"
      sx={{
        height: {
          xs: 60,
          md: 80,
        },
        gridArea: "navbar",
        backgroundColor: "background.paper",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        boxShadow: "none",
        borderBottom: "1px solid ",
        borderBottomColor: "grey.accent4",
        alignSelf: 'flex-start'
      }}
    >
      <Container sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          id="navbar-btn"
          size="large"
          sx={{
            padding: 0,
            margin: 0,
          }}
        >
          <ABCWalletLogo />
        </IconButton>
      </Container>
    </AppBar>
  );
};

export default Navbar;
