import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import useTranslation from "./hooks/useTranslation";
import { CreateCustomTheme } from "./theme";

import Home from "./pages/home";

const Loading = () => <p>Loading ...</p>;

function App() {
  const { initializeTranslation } = useTranslation();
  const theme = CreateCustomTheme({});

  useEffect(() => {
    initializeTranslation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="*" element={<Home />} />
        </Routes>
      </React.Suspense>
    </ThemeProvider>
  );
}

export default App;
