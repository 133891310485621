import React from "react";
import { Box } from "@mui/material";

import Navbar from "../../components/Navbar";
import Maintenance from "../../components/Maintenance";

const Home = () => {
  return (
    <Box
      data-testid="homepage"
      sx={{
        width: "100vw",
        height: "90vh",
      }}
    >
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          paddingX: 2,
        }}
      >
        <Maintenance />
      </Box>
    </Box>
  );
};

export default Home;
