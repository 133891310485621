const getLanguage = () => {
  let url = new URL(window.location.href);
  const urlLang = url.searchParams.get("lang");
  const localStorageLang = localStorage.getItem("lang");
  if (urlLang) {
    return urlLang;
  } else if (localStorageLang) {
    return localStorageLang;
  }
  return "en";
};

export default getLanguage;

export const getCurrentLanguage = () => {
  return localStorage.getItem("lang") || "th";
};

export const getValueByLanguage = (obj: any )=> {
  if (!obj) {
    return "";
  }
  return obj[getCurrentLanguage()] || "";
};
